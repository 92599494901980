export const RingCards = [
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Cartier%20Love%20Rings/Cartier%20RoseGold%20Ring/il_794xN.4962958576_jdlf.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Cartier%20Love%20Rings/Cartier%20RoseGold%20Ring/il_794xN.4962958576_jdlf.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },

  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Evil%20Eye%20Zircconia%20Ring/1700913676270.jpg?raw=trueg",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Evil%20Eye%20Zircconia%20Ring/1700913676273.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },

  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Cartier%20Love%20Rings/Cartier%20RoseGold%20Ring/rosegold_7765e78b-dbc8-4026-8d69-08882b087264_700x.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Trio%20zirconia%20adjustable%20ring/Copy%20of%20moon%20made%20rings%20(1).jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Twisted%20infinity%20ring/infinity%20(2).png?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/White%20Stone%20Ring%20Set/Group7_f6d91b47-61d1-40d5-a035-be03320450d1_800x.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/buckel%20ring/1698140540395.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Evil%20Eye%20Zircconia%20Ring/1700913676273.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Tennis%20adj%20Ring/il_794xN.3541933088_gtjo%20(1).jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Roman%20zirconia%20ring/InfinityRomanDiamondRing_c0e9e80.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/buckel%20ring/1698140540395.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/buckel%20ring/1698140540395.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Big%20Butterfly%20ring/7119wJxuaUL._AC_UX569_.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Tennis%20adj%20Ring/il_794xN.4331626435_7rdk%20(1).jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Trio%20zirconia%20adjustable%20ring/il_794xN.2704802910_bz1k.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/Rings/Butterfly%20ring/IMG_20230519_213057.jpg?raw=true",
    title: "Cartier Gold",
    color: "Gold",
    discountedPrice: 499,
    price: 1499,
    rating: 2.5,
    discountPercent: "66%",
    size: [
      {
        name: "6",
        quantity: 20,
      },
      {
        name: "7",
        quantity: 30,
      },
      {
        name: "8",
        quantity: 50,
      },
    ],
    quantity: 100,
    topLevelCategory: "Women",
    secondLevelCategory: "Clothing",
    thirdLevelCategory: "tops",
    description:
      "A traditional garment embodying elegance and grace. Crafted from fine fabrics, it features intricate embroidery and a relaxed fit, providing comfort and style.",
  },
];
