export const MainCarouselData = [
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/bannerher.png?raw=true",
  },
  {
    Image:
      "https://github.com/sunit2003/MoonMadeDatabase/blob/main/bannerhim.png?raw=true",
  },
  // {
  //   Image: "bannerval.png",
  // },
];
