// Action Types
export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

// Action types for confirmed order
export const CONFIRMED_ORDER_REQUEST = "CONFIRMED_ORDER_REQUEST";
export const CONFIRMED_ORDER_SUCCESS = "CONFIRMED_ORDER_SUCCESS";
export const CONFIRMED_ORDER_FAILURE = "CONFIRMED_ORDER_FAILURE";

// Action types for placed order
export const PLACED_ORDER_REQUEST = "PLACED_ORDER_REQUEST";
export const PLACED_ORDER_SUCCESS = "PLACED_ORDER_SUCCESS";
export const PLACED_ORDER_FAILURE = "PLACED_ORDER_FAILURE";

// Action types for delivered order
export const DELIVERED_ORDER_REQUEST = "DELIVERED_ORDER_REQUEST";
export const DELIVERED_ORDER_SUCCESS = "DELIVERED_ORDER_SUCCESS";
export const DELIVERED_ORDER_FAILURE = "DELIVERED_ORDER_FAILURE";

// Action types for canceled order
export const CANCELED_ORDER_REQUEST = "CANCELED_ORDER_REQUEST";
export const CANCELED_ORDER_SUCCESS = "CANCELED_ORDER_SUCCESS";
export const CANCELED_ORDER_FAILURE = "CANCELED_ORDER_FAILURE";

// Action types for deleting an order
export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";

// actionTypes.js for shipped order
export const SHIP_ORDER_REQUEST = "SHIP_ORDER_REQUEST";
export const SHIP_ORDER_SUCCESS = "SHIP_ORDER_SUCCESS";
export const SHIP_ORDER_FAILURE = "SHIP_ORDER_FAILURE";
